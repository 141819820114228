import Backbone from 'backbone';
import _each from 'lodash/each';
import vent from './site-search-events';
import { 
    dateFromParamName, 
    facetsParamName,  
    offsetParamName, 
    sortingParamName, 
    dateToParamName, 
    pagingParamName, 
    pageSizeParamName, 
    queryParamName,
    filterParamNamePrefix,    
} from '../lib/params';
import { translateParamName, translateFilterParamName } from '../lib/helpers';

// @lends module:SiteSearchQuery.QueryModel.prototype
const QueryModel = Backbone.Model.extend({
    defaults: {
        queryObj: {},
    },
    initialize() {
        this.createQueryObj();

        //Listen to dom event
        document.addEventListener('facets-changed', (e) => this.updateQueryFacets(e.detail));
    },
    /**
     * Set queryObj into model
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#createQueryObj
     */
    createQueryObj() {
        var inst = this,
            queryObj = [],
            searchParams = new URLSearchParams(window.location.search);

        searchParams.forEach(function (value, key) {
            try {
                if (key === facetsParamName) {
                    queryObj[key] = JSON.parse(
                        decodeURIComponent(value.replace('+', ' '))
                    );
                } else {
                    queryObj[decodeURIComponent(key)] =
                        key && key.trim().length > 0
                            ? decodeURIComponent(value.replace('+', ' '))
                            : null;
                }
            } catch (e) {
                if (key === facetsParamName) {
                    queryObj[key] = JSON.parse(
                        decodeURIComponent(value.replace('+', ' '))
                    );
                } else {
                    queryObj[key] =
                        key && key.trim().length > 0
                            ? value.replace('+', ' ')
                            : null;
                }
            }
        });

        this.set({
            queryObj: queryObj,
        });
    },
    /**
     * Retrieves the stored queryObj. Re-creates and returns
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#parseQueryStringParameters
     * @returns {Object} querystring parameters as an object
     */
    getQueryObj() {
        var queryObj = this.get('queryObj');

        if (queryObj && typeof queryObj !== 'undefined') {
            return queryObj;
        }
        //Regenerate if not available
        this.createQueryObj();
        queryObj = this.get('queryObj');

        return queryObj;
    },
    /**
     * Empty queryObj
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#clearQueryObj
     */
    clearQueryObj() {
        this.set({
            queryObj: [],
        });
    },

    updateQueryFacets(selectedFacets) {
        let instance = this;
        let queryObj = this.get('queryObj');
        let searchParams = new URLSearchParams(window.location.search);

        queryObj[facetsParamName] = selectedFacets;
        instance.set('queryObj', queryObj);

        if (selectedFacets.facets.length > 0) {
            searchParams.set(
                facetsParamName,
                JSON.stringify(selectedFacets)
            );
        } else {
            searchParams.delete(facetsParamName);
        }

        let state = {
            query: queryObj.toString(),
        };

        // Remove any pagination when facets change
        delete queryObj[pagingParamName];
        delete queryObj[offsetParamName];
        if (searchParams.has(pagingParamName)) {
            searchParams.delete(pagingParamName);
        }

        history.pushState(state, '', location.pathname + '?' + searchParams.toString());

        // Trigger get data by passing signature of triggering component
        // Remark: facet functionality does not currently support the "sig", which means only one facet per page in querystring will be expected/supported
        vent.trigger('getResultsData', {
            signatures: [''],
        });
    },

    /**
     * updates query text in the querystring
     * @param {String} queryText query text
     * @param {String} sig an Array of signatures
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#updateQueryText
     */
    updateQueryText(queryText, signatures) {
        var inst = this,
            queryObj = this.get('queryObj'),
            searchParams = new URLSearchParams(window.location.search),
            queryKey,
            offsetKey,
            pagingKey,
            pageSizeKey,
            sortingKey;

        if (
            typeof signatures === 'undefined' ||
            signatures === null ||
            signatures.length == 0
        ) {
            signatures = [''];
        }

        //Update queryObject for each signature
        _each(signatures, function (sig) {
            //Determing actual keys with signature if present.
            queryKey = translateParamName(sig, queryParamName);
            offsetKey = translateParamName(sig, offsetParamName);
            pagingKey = translateParamName(sig, pagingParamName);
            pageSizeKey = translateParamName(sig, pageSizeParamName);
            sortingKey = translateParamName(sig, sortingParamName);

            if (queryObj[queryKey] != queryText) {
                // Since search term has actually changed, clear out any facets that may exists, and remove from the search params
                queryObj[facetsParamName] = {};
                if (searchParams.has(facetsParamName)) {
                    searchParams.delete(facetsParamName);
                }
                // Since search term has actually changed, clear out any date filters that may exists, and remove from the search params
                queryObj[dateFromParamName] = {};
                if (searchParams.has(dateFromParamName)) {
                    searchParams.delete(dateFromParamName);
                }
                queryObj[dateToParamName] = {};
                if (searchParams.has(dateToParamName)) {
                    searchParams.delete(dateToParamName);
                }
            }
            queryObj[queryKey] = queryText;

            //Update if exists QueryText and delete other entries
            if (queryObj[queryKey]) {
                for (const [key, value] of Object.entries(queryObj)) {
                    if (key === queryKey) {
                        queryObj[key] = queryText;
                    } else if (
                        key === offsetKey ||
                        key === pagingKey ||
                        key === pageSizeKey ||
                        key === sortingKey ||
                        key.indexOf(filterParamNamePrefix) > -1
                    ) {
                        delete queryObj[key];
                    }
                }
            }
            inst.set('queryObj', queryObj);

            //Set new queryText in querystring
            searchParams.set(queryKey, queryText);

            //Remove keys to reset
            if (searchParams.has(pagingKey)) {
                searchParams.delete(pagingKey);
            }
            if (searchParams.has(sortingKey)) {
                searchParams.delete(sortingKey);
            }
        });

        var state = {
            query: queryObj.toString(),
        };

        history.pushState(state, '', location.pathname + '?' + searchParams.toString());
        //Trigger get data by passing signature of triggering component
        vent.trigger('getResultsData', {
            signatures: signatures,
        });
    },
    /**
     * updates querystring parameter
     * @param {String} key string representing paramName
     * @param {String} value string with the value
     * @param {String} value string with the pageNumber
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#updateQueryPagination
     */
    updateQueryPagination(offsetKey, offset, pageNumber) {
        var inst = this,
            queryObj = this.getQueryObj(),
            searchParams = new URLSearchParams(window.location.search),
            pagingKey = pagingParamName,
            sig = '';

        //Update Object and save
        queryObj[offsetKey] = offset;
        this.set('queryObj', queryObj);

        //Update Querystring. Do not track offset in url. Only pg for page number
        //Check for signature and add if exists.
        if (offsetKey.indexOf('_') > 0) {
            sig = offsetKey.split('_')[0];
            pagingKey = translateParamName(sig, pagingParamName);
        }
        searchParams.set(pagingKey, pageNumber);

        var state = {
            query: this.get('queryObj').toString(),
        };

        history.pushState(state, '', location.pathname + '?' + searchParams.toString());

        //Trigger get resutl data by passing signature of triggering component
        vent.trigger('getResultsData', {
            signatures: [sig],
        });
    },
    /**
     * updates query text in the querystring
     * @param {String} queryText query text
     * @param {String} sig the signature
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#updateQuerySorting
     */
    updateQuerySorting(sortingKey, sortCode) {
        var inst = this,
            queryObj = this.get('queryObj'),
            searchParams = new URLSearchParams(window.location.search),
            sig = '';

        //Extract Signature if present
        if (sortingKey.indexOf('_') > 0) {
            sig = sortingKey.split('_')[0];
        }

        //Update SortCode and save object
        queryObj[sortingKey] = sortCode;
        this.set('queryObj', queryObj);

        //Set new sortCode in querystring
        searchParams.set(sortingKey, sortCode);

        var state = {
            query: queryObj.toString(),
        };

        history.pushState(state, '', location.pathname + '?' + searchParams.toString());

        //Trigger get data by passing signature of triggering component
        vent.trigger('getResultsData', {
            signatures: [sig],
        });
    },
    /**
     * updates querystring parameter
     * @param {String} key string representing paramName
     * @param {String} value string with the value
     * @param {String} value string with the pageNumber
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#resetQueryPagination
     */
    resetQueryPagination(offsetKey, offset, pageNumber) {
        var inst = this,
            queryObj = this.getQueryObj(),
            searchParams = new URLSearchParams(window.location.search),
            pagingKey = pagingParamName,
            sig = '';

        //Update Object and save
        queryObj[offsetKey] = offset;
        this.set('queryObj', queryObj);

        //Update Querystring. Do not track offset in url. Only pg for page number
        //Check for signature and add if exists.
        if (offsetKey.indexOf('_') > 0) {
            sig = offsetKey.split('_')[0];
            pagingKey = translateParamName(sig, pagingParamName);
        }
        searchParams.set(pagingKey, pageNumber);

        var state = {
            query: this.get('queryObj').toString(),
        };

        history.pushState(state, '', location.pathname + '?' + searchParams.toString());
    },
    /**
     * updates query text in the querystring
     * @param {String} queryText query text
     * @param {String} sig the signature
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#updateQuerySorting
     */
    updateQueryDateRange(dateFrom, dateTo, signatures) {
        var inst = this,
            queryObj = this.get('queryObj'),
            searchParams = new URLSearchParams(window.location.search),
            dateFromKey = dateFromParamName,
            dateToKey = dateToParamName,
            offsetKey,
            pagingKey,
            pageSizeKey;

        if (
            typeof signatures === 'undefined' ||
            signatures === null ||
            !signatures.length ||
            (signatures.length === 1 && signatures[0] === '')
        ) {
            signatures = [''];
        }

        _each(signatures, function (sig) {
            offsetKey = translateParamName(sig, offsetParamName);
            dateFromKey = translateParamName(sig, dateFromParamName);
            dateToKey = translateParamName(
                sig,
                dateToParamName
            );
            pagingKey = translateParamName(sig, pagingParamName);
            pageSizeKey = translateParamName(sig, pageSizeParamName);

            //delete any existing pagination keys from the query model object
            for (const [key, value] of Object.entries(queryObj)) {
                if (
                    key === offsetKey ||
                    key === pagingKey ||
                    key === pageSizeKey
                ) {
                    delete queryObj[key];
                }
            }

            //Update dateFrom and save object
            queryObj[dateFromKey] = dateFrom;
            queryObj[dateToKey] = dateTo;

            //Set new dateFrom in querystring
            searchParams.set(dateFromKey, dateFrom);
            searchParams.set(dateToKey, dateTo);

            //Remove keys to reset
            if (searchParams.has(pagingKey)) {
                searchParams.delete(pagingKey);
            }
        });

        //Update QueryObj
        inst.set('queryObj', queryObj);

        var state = {
            query: queryObj.toString(),
        };

        history.pushState(state, '', location.pathname + '?' + searchParams.toString());

        //Trigger get data by passing signature of triggering component
        vent.trigger('getResultsData', {
            signatures: signatures,
        });
    },
    /**
     * updates query text in the querystring
     * @param {String} queryText query text
     * @param {String} sig the signature
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#updateQuerySorting
     */
    removeQueryDateRange(signatures) {
        var inst = this,
            queryObj = this.get('queryObj'),
            searchParams = new URLSearchParams(window.location.search),
            dateFromKey = dateFromParamName, //Default to Param Name
            dateToKey = dateToParamName; //Default to Param Name

        if (
            typeof signatures === 'undefined' ||
            signatures === null ||
            !signatures.length ||
            (signatures.length === 1 && signatures[0] === '')
        ) {
            signatures = ['']; //Set signatures to one single empty array entry.
        }

        _each(signatures, function (sig) {
            dateFromKey = translateParamName(sig, dateFromParamName);
            dateToKey = translateParamName(
                sig,
                dateToParamName
            );

            //If present, delete dateFrom and dateTo from Query object
            if (queryObj[dateFromKey]) {
                delete queryObj[dateFromKey];
            }
            if (queryObj[dateToKey]) {
                delete queryObj[dateToKey];
            }

            //Remove dateFrom and dateTo keys from URL
            if (searchParams.has(dateFromKey)) {
                searchParams.delete(dateFromKey);
            }
            if (searchParams.has(dateToKey)) {
                searchParams.delete(dateToKey);
            }
        });

        //Update QueryObj
        inst.set('queryObj', queryObj);

        var state = {
            query: queryObj.toString(),
        };

        history.pushState(state, '', location.pathname + '?' + searchParams.toString());

        //Trigger get data by passing signature of triggering component
        vent.trigger('getResultsData', {
            signatures: signatures,
        });
    },
    /**
     * Adds a query filter to the queryObj
     * @param {String} queryText query text
     * @param {String} sig the signature
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#addQueryFilter
     */
    addQueryFilter(filterName, filterValues, signature) {
        var inst = this,
            queryObj = this.get('queryObj'),
            filterParamName = translateFilterParamName(
                filterName,
                signature
            ),
            filterValue;

        //Update queryObj with filters
        if (queryObj.hasOwnProperty(filterParamName)) {
            for (let i = 0; i < filterValues.length; i++) {
                filterValue = filterValues[i];
                if (queryObj[filterParamName].indexOf(filterValue) === -1) {
                    queryObj[filterParamName] += '|' + filterValue;
                }
            }
        } else {
            queryObj[filterParamName] = filterValues.join('|');
        }

        this.set('queryObj', queryObj);
    },
    /**
     * Returns an array of filters set the queryObj
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#getQueryFilters
     * @returns {Array} An array of filters if present
     */
    getQueryFilters(signature) {
        var inst = this,
            queryObj = this.get('queryObj'),
            filters = {};

        if (
            typeof signature === undefined ||
            signature === null ||
            signature == ''
        ) {
            signature = '';
        } else {
            signature += '_';
        }
        for (const [key, value] of Object.entries(queryObj)) {
            if (key.startsWith(signature + filterParamNamePrefix)) {
                filters[key] = queryObj[key];
            }
        }

        return filters;
    },
    /**
     * translateSignatures
     * @param {*} rawSignature
     * @param {*} f
     */
    //translateSignatures(rawSignature, paramName) {
    //    var signatures, i;

    //    paramName = paramName.toLowerCase();

    //    if (typeof rawSignature === "undefined" || rawSignature === null) {
    //        return [paramName];
    //    }

    //    signatures = rawSignature.split(',');

    //    if (rawSignature === "") {
    //        return [paramName];
    //    } else {
    //        for (i = 0; i < signatures.length; i++) {
    //            signatures[i] = signatures[i] + "_" + paramName;
    //        }
    //        return signatures;
    //    }
    //},
    /**
     * Checks if queryObj has filters
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#updateHash
     * @returns {Boolean} True - if filters are present, False if not
     */
    hasFiltersInQuery(signature) {
        var inst = this,
            queryObj = this.get('queryObj'),
            isFilters = false;

        if (typeof signature === undefined || signature === null) {
            signature = '';
        } else {
            signature += '_';
        }
        for (const [key, value] of Object.entries(queryObj)) {
            if (key.indexOf(signature + filterParamNamePrefix)) {
                isFilters = true;
                break;
            }
        }

        return isFilters;
    },
    /**
     * Checks if signature present in querystring
     * @param {Object} querystring querystring object
     * @param {String} signature signature for searching in querystring parameter
     * @memberof SiteSearchQuery.QueryModel
     * @alias SiteSearchQuery.QueryModel#updateHash
     * @returns {Boolean} True - if querystring present, False if not
     */
    isSignaturePresentInQueryString(querystring, signature) {
        var querystringKeys = Object.keys(querystring),
            isSignatureFoundInQuery = false;

        for (var len = querystringKeys.length, i = 0; i < len; i++) {
            if (querystringKeys[i].startsWith(signature)) {
                isSignatureFoundInQuery = true;
                break;
            }
        }

        return isSignatureFoundInQuery;
    },
});

const queryModel = new QueryModel();

export default queryModel;
