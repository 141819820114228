import Backbone from 'backbone';
import jQuery from "jquery";
import vent, { searchResultsLoadedEvent, searchResultsLoadingEvent } from '../lib/site-search-events';
import queryModel from '../lib/site-search.query';
import { offsetParamName, sortingParamName } from '../lib/params';
import siteSearchService from '../lib/site-search-service';
import { RESULTS_LOADING } from '../lib/events';
import { translateParamName } from '../lib/helpers';

export const SiteSearchResultView = Backbone.View.extend(
    /** @lends module:siteSearchResults.SiteSearchResultView.prototype **/
    {
        /**
         * Initially sets data to model and watches events on which
         * view should be updated
         * @listens module:siteSearchResults.SiteSearchResultModel~event:change:loadingInProgress
         * @listens module:siteSearchResults.SiteSearchResultModel~event:change:loadingMoreInProgress
         * @listens module: XA.component.siteSearch.vent~event: resultDataMore-changed
         * @listens module: XA.component.siteSearch.vent~event: resultData-changed
         * @listens module: XA.component.siteSearch.vent~event: loadMore
         * @listens module: XA.component.siteSearch.vent~event: getResultsData
         * @memberof module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#initialize
         */
        initialize({ template }) {
            this.template = template;
            var dataProperties = this.$el.data(),
                maxHeight = 0,
                inst = this;

            if (dataProperties.properties.sig === null) {
                dataProperties.properties.sig = '';
            }

            if (this.model) {
                this.model.set({
                    dataProperties: dataProperties.properties,
                    resultData: dataProperties.initialize,
                    noResultsText: dataProperties.properties.noResultsText,
                    nullSearchText:
                        dataProperties.properties.nullSearchText,
                    showNullSearchText:
                        dataProperties.properties.showNullSearchText,
                    isNullSearch: dataProperties.properties.isNullSearch,
                    spellingTerm: dataProperties.properties.spellingTerm,
                    showBestBets: dataProperties.properties.showBestBets,
                    showImage: dataProperties.properties.showImage,
                    showPdfPages: dataProperties.properties.showPdfPages,
                    hideAuthors: dataProperties.properties.hideAuthors,
                    filteredList: dataProperties.properties.filteredList,
                    noResultsTopicsText: dataProperties.properties.noResultsTopicsText,
                });
            }

            //Fire Dom event, initial-results-loading
            //Fire events
            this.el.dispatchEvent(searchResultsLoadedEvent({
                cid: 'controlid',
                resultData: this.model.get('resultData'),
                dataProperties: this.model.get('dataProperties'),
            }));

            this.model.on('change:loadingInProgress', this.loading, this);
            this.model.on(
                'change:loadingMoreInProgress',
                this.loadingMore,
                this
            );

            //Listen to resultDataMore-changed event
            vent.on('resultDataMore-changed', function () {
                inst.renderPart();
            });

            //Listen to resultData-changed event
            vent.on('resultData-changed', function () {
                inst.render();
            });

            vent.on('loadMore', function (data) {
                var signature = inst.model.get('dataProperties').sig;
                if (data.sig === signature) {
                    siteSearchService.getSearchModelData(inst.model, {
                        loadMore: 'true',
                        p: inst.model.get('dataProperties').p,
                        singleRequestMode: signature,
                    });
                }
            });

            //Listen to getResultsData event and trigger data retrieval
            //if signatures match or both triggered data and component have empty signature
            vent.on('getResultsData', function (data) {
                var signature = inst.model.get('dataProperties').sig;
                if (data && data.signatures) {
                    for (var i = 0; i < data.signatures.length; i++) {
                        if (data.signatures[i] === signature) {
                            siteSearchService.getSearchModelData(
                                inst.model
                            );
                            break;
                        }
                    }
                }
            });

            vent.on(RESULTS_LOADING, (evt) => {    
                // Forward event to non-backbone client
                this.el.dispatchEvent(searchResultsLoadingEvent(evt))
            });

            vent.on('results-loaded', (evt) => {   
                // Forward event to non-backbone client   
                this.el.dispatchEvent(searchResultsLoadedEvent({
                    cid: this.model.cid,
                    resultData: evt.data,
                    dataProperties: this.model.get('dataProperties'),
                    queryObj: evt.queryObj,
                }));
            });

            this.render();
        },
        /**
         * list of events for Backbone View
         * @memberof module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#events
         */
        events: {
            'click .page-selector-list a': 'handlePaginationClick',
            'click .page-selector-item-link': 'updateSelectedValue',
            'click .page-selector-item-first a': 'showFirstPage',
            'click .page-selector-item-last a': 'showLastPage',
            'click .page-selector-item-previous a': 'showPrevPage',
            'click .page-selector-item-next a': 'showNextPage',
            'click .sort-selector-link': 'sortBy',
        },

        /**
         * Handles the pagination click event. Primarily used to scroll to the top of the search results
         * @param {Event} event Event object that contains target with selected component value
         * @memberOf module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#updateSelectedValue
         */
        handlePaginationClick(event) {
            event.preventDefault();
            var resultsContainer = jQuery(event.target).closest(
                '.result-list-container'
            );
            var resultsOffset = resultsContainer.offset();
            window.scrollTo(0, resultsOffset.top);
        },

        /**
         * Updates hash according to changed component value
         * @param {Event} event Event object that contains target with selected component value
         * @memberOf module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#updateSelectedValue
         */
        updateSelectedValue(event) {
            event.preventDefault();

            var sig = encodeURIComponent(
                    this.model.get('dataProperties').sig
                ),
                dataProp = jQuery(event.target).data();

            queryModel.updateQueryPagination(
                translateParamName(
                    sig,
                    offsetParamName
                ),
                dataProp.offset,
                dataProp.itemnumber
            );
        },
        /**
         * Switches page selector to the first page and updates hash parameters
         * @param {Event} event Event object that contains target with selected component value
         * @memberOf module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#showFirstPage
         */
        showFirstPage(event) {
            event.preventDefault();

            var sig = encodeURIComponent(
                    this.model.get('dataProperties').sig
                ),
                dataProp = jQuery(event.target).data();

            queryModel.updateQueryPagination(
                translateParamName(
                    sig,
                    offsetParamName
                ),
                0,
                1
            );
        },
        /**
         * Switches page selector to the last page and updates hash parameters
         * @param {Event} event Event object that contains target with selected component value
         * @memberOf module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#showLastPage
         */
        showLastPage(event) {
            event.preventDefault();

            var resultSet = this.model.get('resultData'),
                lastPageItems =
                    resultSet.pagingInfo.total %
                    resultSet.pagingInfo.pageSize,
                offset =
                    resultSet.pagingInfo.total -
                    (lastPageItems === 0
                        ? resultSet.pagingInfo.pageSize
                        : lastPageItems),
                sig = encodeURIComponent(
                    this.model.get('dataProperties').sig
                );

            queryModel.updateQueryPagination(
                translateParamName(
                    sig,
                    offsetParamName
                ),
                offset,
                parseInt(resultSet.pagingInfo.pageCount)
            );
        },
        /**
         * Switches page selector to the next page and updates hash parameters
         * @param {Event} event Event object that contains target with selected component value
         * @memberOf module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#showNextPage
         */
        showNextPage(event) {
            event.preventDefault();

            var resultSet = this.model.get('resultData'),
                offset = resultSet.pagingInfo.start - 1,
                sig = encodeURIComponent(
                    this.model.get('dataProperties').sig
                );

            if (
                offset + resultSet.pagingInfo.pageSize <
                resultSet.pagingInfo.total
            ) {
                offset += resultSet.pagingInfo.pageSize;
            }

            queryModel.updateQueryPagination(
                translateParamName(
                    sig,
                    offsetParamName
                ),
                offset,
                parseInt(resultSet.pagingInfo.pageNumber) + 1
            );
        },
        /**
         * Switches page selector to the precious page and updates hash parameters
         * @param {Event} event Event object that contains target with selected component value
         * @memberOf module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#showPrevPage
         */
        showPrevPage(event) {
            event.preventDefault();

            var resultSet = this.model.get('resultData'),
                offset = resultSet.pagingInfo.start - 1,
                dataProp = jQuery(event.target).data(),
                sig = encodeURIComponent(
                    this.model.get('dataProperties').sig
                );

            if (offset - resultSet.pagingInfo.pageSize >= 0) {
                offset -= resultSet.pagingInfo.pageSize;
            }

            queryModel.updateQueryPagination(
                translateParamName(
                    sig,
                    offsetParamName
                ),
                offset,
                parseInt(resultSet.pagingInfo.pageNumber) - 1
            );
        },
        /**
         * Switches sort selector chosen option
         * @param {Event} event Event object that contains target with selected component value
         * @memberOf module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#sortBy
         */
        sortBy(event) {
            event.preventDefault();

            var sig = encodeURIComponent(
                    this.model.get('dataProperties').sig
                ),
                dataProp = jQuery(event.target).data();

            queryModel.resetQueryPagination(
                translateParamName(
                    sig,
                    offsetParamName
                ),
                0,
                1
            );

            queryModel.updateQuerySorting(
                translateParamName(
                    sig,
                    sortingParamName
                ),
                dataProp.sortcode
            );
        },
        /**
         * Dynamic Search will have search results filters
         * @param {Object} filters search results filters
         * @return {Boolean} true or false
         */
        isDynamicSearch(filters) {
            return JSON.stringify(filters) !== '{}';
        },
        /**
         * Manage 'loading-in-progress' css class
         * @memberof module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#loading
         */
        loading() {
            var spinner = this.$el.find('.text-center');
            var results = this.$el.find('.result-list-container');

            if (this.model.get('loadingInProgress')) {
                spinner.removeAttr('style');
                results.css('visibility', 'hidden');
            } else {
                spinner.attr('style', 'display:none');
                results.css('visibility', 'visible');
            }
        },
        /**
         * Manage 'loading-more-in-progress' css class
         * @memberof module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#loadingMore
         */
        loadingMore() {
            if (this.model.get('loadingMoreInProgress')) {
                this.$el.addClass('loading-more-in-progress');
            } else {
                this.$el.removeClass('loading-more-in-progress');
            }
        },
        /**
         * Renders search result list
         * @memberof module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#renderPart
         */
        renderPart() {
            //var template = _.template(this.model.get("templateItems"));
            //var templateResult = template({ results: this.model.get("resultDataMore").data });
            //this.$el.find(".search-results-container").append(templateResult);
        },
        /**
         * Renders view
         * @memberof module:siteSearchResults.SiteSearchResultView
         * @alias module:siteSearchResults.SiteSearchResultView#render
         */
        render() {
            var inst = this,
                maxHeight = 0,
                sorting,
                sortOptions = [],
                pagination,
                pages = [],
                templateObj,
                dataProperties = inst.model.get('dataProperties'),
                resultSet = inst.model.get('resultData');                  

            //checks if page is opened from disc - if yes then we are in Creative Exchange mode
            if (window.location.href.startsWith('file://')) {
                return;
            }

            if (typeof resultSet === 'undefined') {
                resultSet = {};
            }

            //Sorting Options
            for (var i = 0; i < dataProperties.sortOptions.length; i++) {
                let sortOption = dataProperties.sortOptions[i];
                sortOptions.push({
                    code: sortOption.code,
                    label: sortOption.label,
                    isActive: sortOption.code == inst.model.getMySorting(),
                });
            }

            //Determine pages
            if (resultSet && resultSet.pagingInfo) {
                let deltaFromCurrentToLast =
                        resultSet.pagingInfo.pageCount -
                        resultSet.pagingInfo.pageNumber,
                    currentPage = resultSet.pagingInfo.pageNumber;

                //If less or equal than 4 pages from current page to first page, loop and inject all pages
                if (currentPage <= 4) {
                    for (let i = 1; i <= currentPage; i++) {
                        let offSet =
                                i > 1
                                    ? (i - 1) *
                                      resultSet.pagingInfo.pageSize
                                    : 0,
                            isActive = i == currentPage;

                        pages.push({
                            number: i,
                            isActive: isActive,
                            offset: offSet,
                        });
                    }
                } else {
                    //Add first page
                    pages.push({ number: 1, isActive: false, offset: 0 });
                    //Add Pre-Ellipsis
                    pages.push({
                        number: '...',
                        isActive: false,
                        offset: 0,
                    });
                    //Add Previous Page
                    pages.push({
                        number: currentPage - 1,
                        isActive: false,
                        offset:
                            (currentPage - 2) *
                            resultSet.pagingInfo.pageSize,
                    });
                    //Add Current Page
                    pages.push({
                        number: currentPage,
                        isActive: true,
                        offset:
                            (currentPage - 1) *
                            resultSet.pagingInfo.pageSize,
                    });
                }

                //If greater than 3 pages from current page to last page add specific items
                if (deltaFromCurrentToLast > 3) {
                    //Add Next Page
                    pages.push({
                        number: currentPage + 1,
                        isActive: false,
                        offset: currentPage * resultSet.pagingInfo.pageSize,
                    });
                    //Add Post-Ellipsis
                    pages.push({
                        number: '...',
                        isActive: false,
                        offset: 0,
                    });
                    //Add Last Page
                    pages.push({
                        number: resultSet.pagingInfo.pageCount,
                        isActive: false,
                        offset:
                            (resultSet.pagingInfo.pageCount - 1) *
                            resultSet.pagingInfo.pageSize,
                    });
                } else {
                    //Add all pages following current page to last page
                    for (
                        let e = currentPage + 1;
                        e <= resultSet.pagingInfo.pageCount;
                        e++
                    ) {
                        let offSet =
                                e > 1
                                    ? (e - 1) *
                                      resultSet.pagingInfo.pageSize
                                    : 0,
                            isActive = e == resultSet.pagingInfo.pageNumber;

                        pages.push({
                            number: e,
                            isActive: isActive,
                            offset: offSet,
                        });
                    }
                }
            }

            //Set sorting model
            sorting = {
                hideSorting: dataProperties.hideSorting,
                sortOptions: sortOptions,
            };

            //Set pagination model
            pagination = {
                hidePaging: dataProperties.hidePaging,
                pagingInfo: resultSet.pagingInfo,
                pages: pages,
            };

            //Set final template model
            templateObj = {
                sorting: sorting,
                pagination: pagination,
                results: resultSet.results,
                noResultsText: dataProperties.noResultsText,
                nullSearchText: dataProperties.nullSearchText,
                showNullSearchText: dataProperties.showNullSearchText,
                isNullSearch: resultSet.isNullSearch,
                spellingTerm: resultSet.spellingTerm,
                showBestBets: dataProperties.showBestBets,
                showImage: dataProperties.showImage,
                showPdfPages: dataProperties.showPdfPages,
                hideAuthors: dataProperties.hideAuthors,
                filteredList: dataProperties.filteredList,
                searchTermMatchingTopic: resultSet.searchTermMatchingTopic,
                noResultsTopicsText: dataProperties.noResultsTopicsText,
                hasResults: pagination?.pagingInfo?.total > 0
            };

            this.template(templateObj).then((renderTemplate) => {
                renderTemplate(inst.$el.children(':first').get(0))
                inst.$el.addClass('rendered'); 
            })     
        }           
    }
);