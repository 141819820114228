import { isInExperienceEditor } from '@cfed/theming/js/helpers';
import { SiteSearchFiltersView } from './view';
import { SiteSearchFiltersModel } from './model';

export default class SearchFiltersCustomElement extends HTMLElement {
    async connectedCallback() {
        if (isInExperienceEditor()) return;

        const view = new SiteSearchFiltersView({
            el: this,
            model: new SiteSearchFiltersModel(),
        });
    }
}
