import { isInExperienceEditor } from '@cfed/theming/js/helpers';
import { SiteSearchBoxModel } from './model';
import { SiteSearchBoxView } from './view';
import vent from '../lib/site-search-events';
import siteSearchService from '../lib/site-search-service';

export default class SearchBoxCustomElement extends HTMLElement {
    constructor() {
        super();

        window.addEventListener('popstate', (event) => {
            if (event.state) {
                siteSearchService.statePopGetData();
                // Trigger query-changed
                vent.trigger('query-changed');
            }
        });
    }

    async connectedCallback() {
        if (isInExperienceEditor()) return;

        const view = new SiteSearchBoxView({
            el: this,
            model: new SiteSearchBoxModel(),
        });

        // is this the right place to do this?
        // per code review with Patrick and Joe, leave it here for now and revisit when we develop the search results component.
        siteSearchService.setInitialState();
    }
}
