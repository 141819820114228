const FEATURE_STRUCTURED_DATA = 'chartStructuredData';

export const isStructuredDataEnabled = () =>
    FEATURE_STRUCTURED_DATA in (window?.siteFeatures ?? {}) ||
    new URL(location.href).searchParams.has(FEATURE_STRUCTURED_DATA);

export const hasOverride = () =>
    'chartStructuredDataProcess' in (window?.siteFeatures ?? {});

export const overrideDataset = (props) =>
    window?.siteFeatures?.chartStructuredDataProcess?.(props) ?? props;

export const charts = [];

export const hasCharts = () => charts.length > 0;
