export function accordionAdapter(charts) {
    document.querySelectorAll('.cf-accordion').forEach((accordionEl) => {
        accordionEl.addEventListener('shown.bs.collapse', (evt) => {
            const tags = [...new Set(charts.map((el) => el.tagName.toLowerCase()))];

            evt.srcElement
                .querySelectorAll(tags.join(','))
                .forEach((el) => el?.reflow?.());
        });
    });
}
