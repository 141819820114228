export function customImport(src, elements = [document.body]) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;

        elements.map((el) => el.appendChild(script));
    });
}

export const isInExperienceEditor = () =>
    document.body.classList.contains('on-page-editor');

// https://vitejs.dev/guide/env-and-mode.html#env-variables
export const baseUrl = import.meta.env.BASE_URL.replace(/\/$/, '');
