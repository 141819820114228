import Backbone from 'backbone';
import vent from '../lib/site-search-events';
import queryModel from '../lib/site-search.query';
import { queryParamName } from '../lib/params';
import urlHelperModel from '../lib/site-search-url';
import { translateParamName } from '../lib/helpers';

export const SiteSearchBoxView = Backbone.View.extend(
    /** @lends module:siteSearchBox.SiteSearchBoxView.prototype **/
    {
        /**
         *Sets up watchers for change on model
         * @memberof module:siteSearchBox.SiteSearchBoxView.prototype
         */
        initialize() {
            var inst = this,
                dataProperties = this.$el.data(),
                form = this.$el.find('form'),
                searchInput = form.find('input.site-search-box-input');

            //Set reference to searchInput
            this.searchInput = searchInput;

            if (this.model) {
                this.model.set({
                    dataProperties: dataProperties.properties,
                    signatures: this.model.getSignatures(
                        dataProperties.properties.searchResultsSignatures
                    ),
                });
            }

            //Set SearchInput to initial value.
            this.updateSearchBoxValue();

            vent.on('query-changed', this.updateSearchBoxValue.bind(this));
        },
        /**
         * list of events for Backbone View
         * @memberof module:siteSearchBox.SiteSearchBoxView
         * @alias module:siteSearchBox.SiteSearchBoxView#events
         */
        events: {
            'submit form': 'updateQuery',
        },
        /**
         * Updates hash value and makes redirect based on value from search box
         * @memberof module:siteSearchBox.SiteSearchBoxView
         * @param {jQuery<Event>} event jQuery event object
         * @alias module:siteSearchBox.SiteSearchBoxView#updateQueryWithRedirect
         */
        updateQuery(event) {
            event.preventDefault();

            var dataProperties = this.model.get('dataProperties'),
                signatures = this.model.get('signatures'),
                query = this.searchInput.val();

            //Trim query term(s)
            query = query.trim();

            if (dataProperties.isSearchPage) {
                queryModel.updateQueryText(query, signatures);
                //Trigger query-changed
                vent.trigger('query-changed');
            } else {
                //Redirect with query for all signatures
                window.location.href =
                    urlHelperModel.createRedirectSearchUrl(
                        dataProperties.siteSearchPage,
                        signatures,
                        query
                    );
            }
        },
        /**
         * Sets value into search box based on queryObject
         * @memberof module:siteSearchBox.SiteSearchBoxView
         * @alias module:siteSearchBox.SiteSearchBoxView#updateSearchBoxValue
         */
        updateSearchBoxValue() {
            var queryObj = queryModel.getQueryObj(),
                signatures = this.model.get('signatures'),
                queryText;

            //Check for any signature first
            if (signatures.length > 0) {
                for (var i = 0; i < signatures.length; i++) {
                    var signatureParam = translateParamName(
                        signatures[i],
                        queryParamName
                    );
                    if (queryObj.hasOwnProperty(signatureParam)) {
                        try {
                            queryText = decodeURIComponent(
                                queryObj[signatureParam]
                            );
                            if (queryText !== this.searchInput.val()) {
                                this.searchInput.val(queryText);
                            }
                        } catch (e) {
                            this.searchInput.val(
                                queryObj[signatureParam]
                            ); /* just use what was passed in */
                        }
                    } else {
                        this.searchInput.val('');
                    }
                }
            } else {
                //No Signature case
                queryText = queryObj.hasOwnProperty(
                    queryParamName
                )
                    ? queryObj[queryParamName]
                    : '';
                if (queryText !== this.searchInput.val()) {
                    this.searchInput.val(queryText);
                }
            }
        },
    }
);
