import { customImport, baseUrl } from '@cfed/theming/js/helpers';

export default async function initNowcasting() {
    const charts = document.querySelectorAll('.nowcasting-chart');

    if (charts.length > 0) {
        await customImport(`${baseUrl}/bundles/fusioncharts.js`);
        const component = await import('./component-nowcasting-chart');
        const instance = component.default();

        charts.forEach((elem) => instance.init(elem));
    }
}
