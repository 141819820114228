import { isInExperienceEditor, baseUrl } from '@cfed/theming/js/helpers';

const FONT_URL = `${baseUrl}/fonts/mathjax`;

function equations() {
    return {
        async init() {
            if (isInExperienceEditor()) {
                return;
            }

            const mathElements = [
                'math',
                '.equation-cont',
                '.rich-text.rich-text--equation',
            ].join(',');

            if (document.querySelectorAll(mathElements).length > 0) {
                window.MathJax = {
                    startup: {
                        ready() {
                            // eslint-disable-next-line no-undef
                            MathJax.config.chtml.fontURL = FONT_URL;
                            // eslint-disable-next-line no-undef
                            MathJax.config.chtml.font.options.fontURL = FONT_URL;
                            // eslint-disable-next-line no-undef
                            MathJax.startup.defaultReady();
                        },
                    },
                    options: {
                        enableMenu: false, // set to false to disable the menu
                    },
                };

                await import('./mathjax.min');
            }
        },
    };
}

export default equations;
