import {
    hasCharts,
    isStructuredDataEnabled,
    hasOverride,
    overrideDataset,
    charts,
} from '@cfed/indicators-and-data/context';
import { accordionAdapter } from './helpers/accordion-adapter';

export async function initSiteCharts() {
    await Promise.all([
        import('./components/chart-highcharts'),
        import('./components/chart-nowcasting'),
        import('./components/survey/chart-survey'),
        import('./components/sorce/chart-sorce'),       
    ]);

    if (!hasCharts()) return;

    accordionAdapter(charts);

    if (isStructuredDataEnabled()) {
        const chartMetaService = (await import('./services/meta-service'))
            .default;

        let dataset = chartMetaService.createDataSet();
        if (hasOverride) dataset = overrideDataset(dataset);
        const scriptEl = chartMetaService.createScriptElement(dataset);

        document.head.appendChild(scriptEl);
    }
}
