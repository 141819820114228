import Backbone from 'backbone';
import _each from 'lodash/each';
import jQuery from "jquery";
import queryModel from './site-search.query';
import urlHelperModel from './site-search-url';
import { sortingParamName, offsetParamName, facetsParamName, pageSizeParamName } from './params'
import { translateParamName, translateFilterParamName } from './helpers';
import vent from './site-search-events';

/**
 * @name module:siteSearch.UrlHelperModel
 * @constructor
 * @augments Backbone.Model
 */
var SiteSearchServiceModel = Backbone.Model.extend(
    /** @lends module:siteSearch.UrlHelperModel.prototype **/
    {
        /**
         * sets initial site search state
         * @memberof siteSearch.UrlHelperModel
         * @alias siteSearch.UrlHelperModel#setInitialState
         */
        setInitialState() {
            var queryObj = queryModel.getQueryObj();
            var searchParams = new URLSearchParams(window.location.search);

            var state = {
                query: queryObj.toString(),
            };

            history.replaceState(state, '', window.location.href);
        },
        /**
         * Processes ALL search result data after state pop
         * @param {Object} overrideProps properties for overriding
         * @memberof module:SiteSearchServiceModel.SiteSearchServiceModel
         * @alias module:SiteSearchServiceModel.SiteSearchServiceModel#getSearchModelData
         */
        statePopGetData(props) {
            var that = this,
                overrideProps = props;

            //Clear and regenerate QueryObj based on URl
            queryModel.clearQueryObj();
            queryModel.createQueryObj();

            //Loop over all searchResultsModels and retrieve data
            _each(
                // eslint-disable-next-line no-undef
                XA.component.siteSearch.results.siteSearchResultModels,
                function (searchModel) {
                    that.getSearchModelData(searchModel, overrideProps);
                }
            );
        },
        /**
         * Processes search result data
         * @param {Object} searchModel search model to update
         * @param {Object} overrideProps properties for overriding
         * @memberof module:SiteSearchServiceModel.SiteSearchServiceModel
         * @alias module:SiteSearchServiceModel.SiteSearchServiceModel#getSearchModelData
         */
        getSearchModelData(searchModel, overrideProps) {
            var queryObj = queryModel.getQueryObj(),
                that = this,
                defaultSortOrder,
                offsetSignature,
                pageSize,
                searchResultsDefaultFilters,
                signature,
                mergedProps,
                url;

            signature =
                searchModel.get('dataProperties').sig !== null
                    ? encodeURIComponent(searchModel.get('dataProperties').sig)
                    : '';
            offsetSignature = translateParamName(
                signature,
                offsetParamName
            );

            //if (!searchModel.get('dataProperties').autoFireSearch) {
            //    //If queryObj is not present or no criteria with search model signature is present in queryObj, then do not fire search
            //    if (!queryObj || !queryModel.isSignaturePresentInQueryString(queryObj, signature)) {
            //        return;
            //    }
            //}

            defaultSortOrder =
                searchModel.get('dataProperties').defaultSortOrder;
            pageSize = searchModel.get('dataProperties').p;
            var filtered = translateParamName(
                signature,
                facetsParamName
            );
            if (typeof (queryObj[filtered]) !== 'undefined' || (queryObj[filtered]) !== null) {
                if ((!searchModel.get('dataProperties').filteredList) || (searchModel.get('dataProperties').filteredList && queryObj[filtered].facets.length == 0)) {
                    searchResultsDefaultFilters =
                        searchModel.get('dataProperties').filters;
                }
            }

            //if we have singleRequestMode with signature, then we are getting data just for that signature
            if (
                typeof overrideProps !== 'undefined' &&
                overrideProps.hasOwnProperty('singleRequestMode') &&
                overrideProps['singleRequestMode'] != signature
            ) {
                return;
            }

            //if there is no page size param in the query, but we have page size component on the page, then take
            //default page size or first (if default isn't set)
            queryObj = that.setPageSize(signature, pageSize, queryObj);

            //Set OffSet if not present and if a page number is specified
            queryObj = that.setOffset(signature, pageSize, queryObj);

            //If there are Configured Default Filters then add to queryObj
            queryObj = that.setFilters(
                signature,
                searchResultsDefaultFilters,
                queryObj
            );

            mergedProps = jQuery.extend(
                {},
                searchModel.get('dataProperties'),
                queryObj
            );

            mergedProps = jQuery.extend(mergedProps, overrideProps);

            //Get Sorting Order
            mergedProps = that.setSortOrder(
                signature,
                mergedProps,
                defaultSortOrder
            );

            //Generate Search Url
            url = urlHelperModel.createSearchUrl(mergedProps, signature);
            if (!url) {
                return;
            }

            if (!searchModel.checkBlockingRequest()) {
                searchModel.blockRequests(true);

                //Fire events
                vent.trigger('results-loading', {
                    cid: searchModel.cid,
                    sig: signature,
                    queryObj,
                });

                fetch(url)
                    .then((res) => res.json())
                    .then(data => {
                        data.pagingInfo.signature =
                        data.pagingInfo.signature !== null
                            ? data.pagingInfo.signature
                            : '';
  
                        vent.trigger('results-loaded', {
                            dataCount: data.pagingInfo.total,
                            data: data,
                            pageSize:
                                data.pagingInfo.signature !== '' &&
                                mergedProps.hasOwnProperty(
                                    data.pagingInfo.signature + '_p'
                                )
                                    ? mergedProps[
                                        data.pagingInfo.signature + '_p'
                                    ]
                                    : mergedProps.p,
                            offset:
                                data.pagingInfo.signature !== '' &&
                                mergedProps.hasOwnProperty(
                                    data.pagingInfo.signature + '_e'
                                )
                                    ? mergedProps[
                                        data.pagingInfo.signature + '_e'
                                    ]
                                    : mergedProps.e,
                            searchResultsSignature: data.pagingInfo.signature,
                            loadMore: searchModel.get('loadMore'),
                            queryObj
                        });
                    });        
            }
        },
        /**
         * Gets any filters which may be either configured or selected as facets
         * @param {String} signature component signature
         * @param {Object} mergedProps
         * @param {String} searchResultsDefaultFilters default configured filters
         * @memberof module:SearchServiceModel.SearchServiceModel
         * @alias module:SearchServiceModel.SearchServiceModel#getFilters
         * @returns {Object} merged properties
         */
        setFilters(
            signature,
            searchResultsDefaultFilters,
            queryObj
        ) {
            var filterProperty, filterParamName, filterValues, filterValue, i;

            //Merge queryObj filters with configured default filters
            for (filterProperty in searchResultsDefaultFilters) {
                filterParamName = translateFilterParamName(
                    filterProperty,
                    signature
                );
                filterValues = searchResultsDefaultFilters[filterProperty];
                if (queryObj.hasOwnProperty(filterParamName)) {
                    for (i = 0; i < filterValues.length; i++) {
                        filterValue = filterValues[i];
                        if (
                            queryObj[filterParamName].indexOf(filterValue) ===
                            -1
                        ) {
                            queryObj[filterParamName] += '|' + filterValue;
                        }
                    }
                } else {
                    queryObj[filterParamName] = filterValues.join('|');
                }
            }

            return queryObj;
        },
        /**
         * Sorts search result due to selected properties
         * @param {String} signature component signature
         * @param {Object} mergedProps
         * @param {String} searchResultsDefaultSortOrder default sorting order
         * @memberof module:SearchServiceModel.SearchServiceModel
         * @alias module:SearchServiceModel.SearchServiceModel#setSortOrder
         * @returns {Object} merged properties
         */
        setSortOrder(
            signature,
            mergedProps,
            searchResultsDefaultSortOrder
        ) {
            var paramName = translateParamName(
                signature,
                sortingParamName
            );

            if (
                !mergedProps.hasOwnProperty(paramName) &&
                searchResultsDefaultSortOrder !== ''
            ) {
                delete mergedProps.defaultSortOrder;
                delete mergedProps.o;

                mergedProps[paramName] = searchResultsDefaultSortOrder;
            }

            return mergedProps;
        },

        /**
         * Sets default page size into queryObj
         * @param {String} signature component signature
         * @param {Number} pageSize  page size
         * @param {Object} queryObj queryObj represented as an object
         * @memberof module:SearchServiceModel.SearchServiceModel
         * @alias module:SearchServiceModel.SearchServiceModel#setDefaultDefaultPageSize
         * @returns {Object} queryObj object
         */
        setPageSize(signature, pageSize, queryObj) {
            var paramName = translateParamName(
                signature,
                pageSizeParamName
            );

            if (!queryObj.hasOwnProperty(paramName) && pageSize != 0) {
                queryObj[paramName] = pageSize;
            }
            return queryObj;
        },
        /**
         * Sets offset into queryObj if url page number is available
         * @param {String} signature component signature
         * @param {Number} pageSize  page size
         * @param {Object} queryObj queryObj represented as an object
         * @memberof module:SearchServiceModel.SearchServiceModel
         * @alias module:SearchServiceModel.SearchServiceModel#setDefaultOffset
         * @returns {Object} queryObj object
         */
        setOffset(signature, pageSize, queryObj) {
            var pagingParamName = translateParamName(
                    signature,
                    pagingParamName
                ),
                offsetParamName = translateParamName(
                    signature,
                    offsetParamName
                ),
                pageNumber;

            //Add offset if not already present
            if (
                queryObj.hasOwnProperty(pagingParamName) &&
                !queryObj.hasOwnProperty(offsetParamName)
            ) {
                pageNumber = parseInt(queryObj[pagingParamName]);

                if (pageNumber > 1) {
                    //Offset by the total num of items up to the previous page
                    var offSet = (pageNumber - 1) * pageSize;
                    queryObj[offsetParamName] = offSet;
                }
            }

            return queryObj;
        },
        /**
         * Determine if the element is in the current viewport
         * @param {Element} elem the element being looked for in the current viewport
         * @return {Boolean} true or false
         */
        isInViewport(elem) {
            if (jQuery(elem).length == 0) {
                return false;
            }
            var elementTop = jQuery(elem).offset().top;
            var elementBottom = elementTop + jQuery(elem).outerHeight();
            var viewportTop = jQuery(window).scrollTop();
            var viewportBottom = viewportTop + jQuery(window).height();

            return elementBottom > viewportTop && elementTop < viewportBottom;
        },
        /**
         * Determine if the searchbox is in the current viewport
         * @param {Element} elem the sibling result-list element of the searchbox
         * @return {Boolean} true or false
         */
        isSearchBoxInViewport(elem) {
            var searchBox = jQuery(elem).parent().find('.site-search-box');

            return this.isInViewport(searchBox);
        },
    }
);

var siteSearchServiceModel = new SiteSearchServiceModel();

export default siteSearchServiceModel;
