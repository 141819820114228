import Backbone from 'backbone';

export const SiteSearchFiltersModel = Backbone.Model.extend(
    /** @lends module:siteSearchFilters.SiteSearchFiltersModel.prototype **/
    {
        /**
         * Default model options
         * @default
         */
        defaults: {
            dataProperties: {},
            signatures: [],
            yearsRequiredErrorText: '',
            dateRangeErrorText: '',
            years: [],
            months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            monthNames: ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"],
            hidemonth: ''
        },
        /**
         * create instance of
         * @memberOf module:siteSearchFilters.SiteSearchFiltersModel
         * @method
         * @alias module:siteSearchFilters.SiteSearchFiltersModel.initialize
         */
        initialize() {
            var inst = this

            //Get toYear = this year + 1
            const toYear = new Date().getFullYear() + 1;

            //Generate year list
            for (var i = toYear; i >= 1980; i--) {
                inst.get('years').push(i);
            }
        },
        /**
         * get search filters signature
         * @memberOf module:siteSearchFilters.SiteSearchFiltersModel
         * @method
         * @alias module:siteSearchFilters.SiteSearchFiltersModel.getSignature
         * @returns {string} search filters signature
         */
        getSignatures(searchResultsSignatures) {
            var rawSignature =
                typeof searchResultsSignatures === 'undefined' ||
                    rawSignature === null
                    ? this.get('dataProperties').searchResultsSignatures
                    : searchResultsSignatures,
                signatures;

            if (
                typeof rawSignature === 'undefined' ||
                rawSignature === null
            ) {
                return [];
            }

            signatures = rawSignature.split(',');

            return signatures;
        },
    }
);
