import Backbone from 'backbone';

export const SiteSearchBoxModel = Backbone.Model.extend(
    /** @lends module:siteSearchBox.SiteSearchBoxModel.prototype **/
    {
        /**
         * Default model options
         * @default
         */
        defaults: {
            dataProperties: {},
            isOnSearchResultsPage: false,
            sig: [],
        },
        /**
         * create instance of
         * @memberOf module:siteSearchBox.SiteSearchBoxModel
         * @method
         * @alias module:siteSearchBox.SiteSearchBoxModel.initialize
         */
        initialize() {
            var inst = this;
        },
        /**
         * get search box signature
         * @memberOf module:siteSearchBox.SiteSearchBoxModel
         * @method
         * @alias module:siteSearchBox.SiteSearchBoxModel.getSignature
         * @returns {string} search box signature
         */
        getSignatures(searchResultsSignatures) {
            var rawSignature =
                    typeof searchResultsSignatures === 'undefined' ||
                    rawSignature === null
                        ? this.get('dataProperties').searchResultsSignatures
                        : searchResultsSignatures,
                signatures;

            if (
                typeof rawSignature === 'undefined' ||
                rawSignature === null
            ) {
                return [];
            }

            signatures = rawSignature.split(',');

            return signatures;
        },
    }
);