import Backbone from 'backbone';
import _each from 'lodash/each';
import _includes from 'lodash/includes';
import queryModel from './site-search.query';
import {
    queryParamName,
    offsetParamName,
    dateFromParamName,
    dateToParamName,
    sortingParamName,
    facetsParamName,
    pagePathnameParamName,
    pageSizeParamName,
    filterParamNamePrefix,
    filteredListTextParamName,
    signatureParamName,
    showNullSearchTextParamName,
    showBestBetsTextParamName,
    showImageTextParamName,
    showPdfPagesTextParamName,
    hideAuthorsTextParamName,
    buildContextualUrlsParamName
} from './params';
import { translateParamName } from './helpers';

/**
 *
 * @param {String} data
 * @returns {String}
 */
const clearIdData = function (data) {
    data = data + '';
    var parts = data.split(','),
        urlData = [],
        details;
    _each(parts, function (part) {
        details = part.split('|');
        if (_includes(part, '|') && details.length === 3) {
            urlData.push(details[1] + '|' + details[2]);
        } else {
            urlData.push(part);
        }
    });
    return urlData.join(',');
};

/**
 * @name module:siteSearch.UrlHelperModel
 * @constructor
 * @augments Backbone.Model
 */
const UrlHelperModel = Backbone.Model.extend(
    /** @lends module:siteSearch.UrlHelperModel.prototype **/
    {
        createSearchUrl(dataProp, signature) {
            var url = this.setEndpoint(dataProp.endpoint),
                queryKey = translateParamName(signature, queryParamName),
                sortingKey = translateParamName(signature, sortingParamName),
                offSetKey = translateParamName(signature, offsetParamName),
                dateFromKey = translateParamName(signature, dateFromParamName),
                dateToKey = translateParamName(signature, dateToParamName),
                query = this.cleanupQuery(dataProp[queryKey]) /* encode URLs */,
                sorting = dataProp[sortingKey],
                offset = dataProp[offSetKey],
                dateFrom = dataProp[dateFromKey],
                dateTo = dataProp[dateToKey],
                selectedFacets = dataProp[facetsParamName],
                buildContextualUrls = dataProp.buildContextualUrls,
                showNullSearchText = dataProp.showNullSearchText,
                showBestBetsText = dataProp.showBestBets,
                showImageText = dataProp.showImage,
                showPdfPagesText = dataProp.showPdfPages,
                hideAuthorsText = dataProp.hideAuthors,
                filteredListText = dataProp.filteredList,
                pagePathname = this.cleanupQuery(
                    window.document.location.pathname
                );

            query = query == 'undefined' ? '' : query;
            url += query ? '&' + queryParamName + '=' + query : ''; //query

            url += appendParam(sortingParamName, sorting);
            url += appendParam(offsetParamName, offset);

            url += '&' + pageSizeParamName + '=' + dataProp.p; //pageSize

            url += appendParam(dateFromParamName, dateFrom);
            url += appendParam(dateToParamName, dateTo);

            if (
                selectedFacets &&
                selectedFacets.facets &&
                selectedFacets.facets.length > 0
            ) {
                url += appendParam(
                    facetsParamName,
                    encodeURIComponent(JSON.stringify(selectedFacets))
                );
            }

            url += this.getFilterParams(dataProp, signature);

            url += appendParam(signatureParamName, signature);
            url += appendParam(buildContextualUrlsParamName, buildContextualUrls);
            url += appendParam(pagePathnameParamName, pagePathname);
            url += appendParam(showNullSearchTextParamName, showNullSearchText);
            url += appendParam(showBestBetsTextParamName, showBestBetsText);
            url += appendParam(showImageTextParamName, showImageText);
            url += appendParam(showPdfPagesTextParamName, showPdfPagesText);
            url += appendParam(hideAuthorsTextParamName, hideAuthorsText);
            url += appendParam(filteredListTextParamName, filteredListText);
            url += dataProp.itemid ? '&itemid=' + dataProp.itemid : '';
            url = this.fixUrl(url);

            return url;

            function appendParam(myParamKey, myParamVal) {
                return isNotNullOrEmptyOrUndefined(myParamVal)
                    ? `&${myParamKey}=${myParamVal}`
                    : '';
            }

            function isNotNullOrEmptyOrUndefined(variable) {
                return (
                    typeof variable !== 'undefined' &&
                    variable !== null &&
                    variable !== ''
                );
            }
        },
        cleanupQuery(url) {
            try {
                return encodeURIComponent(url);
            } catch (e) {
                return url; // cannot encode url, just use as is
            }
        },

        /**
         * creates url for search components for redirect
         * @param {String} redirectUrl target url
         * @param {String} signatures component signature
         * @param {Object} query query for redirect
         * @returns {String} created url
         * @memberof searchUrl.UrlHelperModel
         * @alias searchUrl.UrlHelperModel#createSearchUrl
         */
        createRedirectSearchUrl(
            redirectUrl,
            signatures,
            query,
            dateFrom,
            dateTo
        ) {
            var url,
                index = 0;

            //Check query text variable
            if (typeof query === 'undefined' || query === null) {
                query = '';
            }
            //Check dateFrom variable
            if (typeof dateFrom === 'undefined' || dateFrom === null) {
                dateFrom = '';
            }
            //Check dateTo variable
            if (typeof dateTo === 'undefined' || dateTo === null) {
                dateTo = '';
            }

            url = this.setEndpoint(redirectUrl + '?');

            if (
                typeof signatures === 'undefined' ||
                signatures === null ||
                !signatures.length ||
                (signatures.length === 1 && signatures[0] === '')
            ) {
                //Add query param if specified
                if (query !== '') {
                    url += queryParamName + '=' + query;
                }

                //Add date range params if specified
                if (dateFrom !== '' && dateTo !== '') {
                    url += query !== '' ? '&' : '';
                    url +=
                        dateFromParamName +
                        '=' +
                        dateFrom +
                        '&' +
                        dateToParamName +
                        '=' +
                        dateTo;
                }
            } else {
                _each(signatures, function (item) {
                    url += index > 0 ? '&' : '';

                    //Add query param if specified
                    if (query !== '') {
                        url +=
                            translateParamName(item, queryParamName) +
                            '=' +
                            query;
                    }

                    //Add date range params if specified
                    if (dateFrom !== '' && dateTo !== '') {
                        url +=
                            translateParamName(item, dateFromParamName) +
                            '=' +
                            dateFrom +
                            '&' +
                            translateParamName(item, dateToParamName) +
                            '=' +
                            dateTo;
                    }
                    index++;
                });
            }

            return url;
        },
        /**
         * Returns url with facet parameters
         * @param {Object} dataProp component properties
         * @param {String} searchResultsSignature signature of search result component
         * @memberof searchUrl.UrlHelperModel
         * @alias searchUrl.UrlHelperModel#getFilterParams
         * @returns {String} url with facet parameters
         */
        getFilterParams(dataProp, searchResultsSignature) {
            var url = '',
                filterName,
                clearFilterName,
                facetSignature,
                filters = queryModel.getQueryFilters(searchResultsSignature);

            if (filters) {
                for (var key in filters) {
                    clearFilterName = key.substring(
                        key.indexOf(filterParamNamePrefix) + 2
                    );
                    facetSignature = key.substr(0, key.indexOf('_'));

                    if (searchResultsSignature === facetSignature) {
                        url +=
                            '&' +
                            clearFilterName +
                            '=' +
                            encodeURIComponent(filters[key]);
                    }
                }
            }

            return url;
        },
        /**
         * Returns url with endpoint
         * @param {string} endpoint endpoint parameter from component properties
         * @memberof searchUrl.UrlHelperModel
         * @alias searchUrl.UrlHelperModel#setEndpoint
         * @returns {String} url with endpoint
         */
        setEndpoint(endpoint) {
            var url = window.location.origin;

            if (endpoint.indexOf(url) !== -1) {
                return endpoint;
            }

            return (url += endpoint);
        },
        /**
         * Fixes url with special characters
         * @param {string} url url string for fixing
         * @memberof searchUrl.UrlHelperModel
         * @alias searchUrl.UrlHelperModel#fixUrl
         * @returns {String} url with endpoint
         */
        fixUrl(url) {
            var index;

            url = url.replace(/[?]/g, '&');
            index = url.indexOf('&');
            url = url.substr(0, index) + '?' + url.substr(index + 1);

            return url;
        },
    }
);

var urlHelperModel = new UrlHelperModel();

export default urlHelperModel;
