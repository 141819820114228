import { JSRenderTemplatesMixin } from '@cfed/site-search/mixins';
import templates from './templates';

@JSRenderTemplatesMixin
export class CFEDTemplateService {
    emptyText(props) {
        if (
            props.isNullSearch &&
            props.showNullSearchText &&
            props.nullSearchText != ''
        ) {
            return props.nullSearchText;
        } else if (
            props.searchTermMatchingTopic &&
            props.noResultsTopicsText != ''
        ) {
            return props.noResultsTopicsText;
        }
        return props.noResultsText || '';
    }

    formatTime(value) {
        return new Intl.DateTimeFormat('en-us', {
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short',
            timeZone: 'America/New_York',
        }).format(Date.parse(value));
    }

    formatDate(value) {
        return new Intl.DateTimeFormat('en-us', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'America/New_York',
        })
            .format(Date.parse(value))
            .replaceAll('/', '.');
    }

    async render(props) {
        await this.getJSTemplates(templates);

        return (el) => {
            el.innerHTML = this.renderJSTemplate('resultList', props, {
                formatDate: this.formatDate,
                formatTime: this.formatTime,
                emptyText: () => this.emptyText(props),
            });
        };
    }
}
