// https://vitejs.dev/guide/features.html#glob-import
const modules = import.meta.glob('./*.tmpl', {
    query: '?raw',
    import: 'default',
});

// Remove path from the template name
// ex) ./resultList.tmpl -> resultList
const templatesByName = Object
    .entries(modules)
    .map(([path, template]) => [
        path.replace(/(^.\/)|(\.tmpl$)/g, ''),
        template,
    ]);

export default templatesByName;
