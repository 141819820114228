import jQuery from 'jquery';
import jsrender from 'jsrender/jsrender';

export const JSRenderTemplatesMixin = (base) =>
    class JSRenderTemplates extends base {
        constructor(...args) {
            super(...args);

            jsrender(jQuery);
        }

        getJSTemplateByName(templateName) {
            return jQuery.templates[templateName];
        }

        async getJSTemplates(modules) {
            const templates = await Promise.all(
                modules.map(async ([name, template]) => [
                    name,
                    await template(),
                ])
            );

            templates.forEach(([name, value]) =>
                jQuery?.templates(name, value)
            );
        }

        renderJSTemplate(template, props, helpers) {
            return this.getJSTemplateByName(template)?.render(props, helpers);
        }
    };
