import {
    queryParamName,
    taxonomyContextParamName,
    signatureParamName,
} from '../lib/params';
import { translateParamName } from '../lib/helpers';
import { toCamelCase } from '../lib/helpers';
import _pick from 'lodash/pick';

export class SearchTaxonomyService {
    initial = {
        taxonomyTerm: '',
        taxonomyDescription: '',
        topicUrl: null,
        isSynonymFound: false,
    };

    createRequestURL(query, sig, taxonomyId) {
        const queryKey = translateParamName(sig, queryParamName);
        const url = new URL(
            '/frbc/taxonomysearch/findtopicterm',
            window.location.origin
        );

        url.searchParams.set(queryKey, query);
        url.searchParams.set(taxonomyContextParamName, taxonomyId);
        url.searchParams.set(signatureParamName, sig);

        return url.toString();
    }

    // Api will respond w/ a 404 if the term is not found which
    // is a very common path. This is used to control workflow
    findTopicTerm(query, sig, taxonomyId) {
        const url = this.createRequestURL(query, sig, taxonomyId);

        return fetch(url)
            .then((response) => response.json())
            .then((data) => toCamelCase(data))
            .then((data) => _pick(data, Object.keys(this.initial)))
            .catch(() => this.initial);
    }
}
