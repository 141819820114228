import _camelCase from 'lodash/camelCase';
import { filterParamNamePrefix } from './params';

export const toCamelCase = (obj) =>
    Object.entries(obj).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [_camelCase(key)]: value,
        }),
        {}
    );

export function translateParamName(sig, paramName) {
    return (!sig ? '' : `${sig}_`) + paramName;
}

export function translateFilterParamName(filterName, sig) {
    return translateParamName(sig, filterParamNamePrefix + filterName);
}
