import { SEARCH_RESULTS_EMPTY } from '@cfed/site-search/events';

export const TAB_SHOW = 'tab-container:show';

export const tagName = 'tab-container';

const tabShowEvent = () => new Event(TAB_SHOW);

export class TabContainer extends HTMLElement {
    constructor() {
        super();

        const tabListEl = this.querySelector('[role=tablist]');

        this.addEventListener(
            SEARCH_RESULTS_EMPTY,
            this._handleSearchResultsEmpty.bind(this)
        );
        tabListEl?.addEventListener('click', this._handleClickTab.bind(this));
        tabListEl?.addEventListener(
            'keydown',
            this._handleKeyDownTab.bind(this)
        );
    }

    _handleSearchResultsEmpty(evt) {
        const content = evt.target.closest('[role=tabpanel]');

        this.removeByPanel(content);
        this.reselectTabs();
    }

    _handleClickTab(evt) {
        const heading = evt.target.closest('[role=tab]');

        this.selectByHeading(heading);
    }

    _handleKeyDownTab(evt) {
        const select = (heading) => {
            if (!heading) return;

            evt.stopPropagation();
            evt.preventDefault();

            this.selectByHeading(heading, { focus: true });
        };

        switch (evt.key) {
            case 'ArrowLeft':
                return select(
                    evt.target?.previousElementSibling ??
                        this.querySelector('[role=tab]:last-child')
                );
            case 'ArrowRight':
                return select(
                    evt.target?.nextElementSibling ??
                        this.querySelector('[role=tab]')
                );
            case 'Home':
                return select(this.querySelector('[role=tab]'));
            case 'End':
                return select(this.querySelector('[role=tab]:last-child'));
        }
    }

    removeByPanel(panel) {
        const heading = document.querySelector(
            `#${panel.getAttribute('aria-labelledby')}`
        );

        panel?.remove();
        heading?.remove();
    }

    select(el, show = true) {
        if (!el) return;

        el.classList.toggle('active', show);
        el.setAttribute('aria-selected', show ? 'true' : 'false');
        el.tabIndex = show ? 0 : -1;
    }

    selectByHeading(heading, { focus = false } = {}) {
        if (!heading || heading?.classList.contains('active')) return;

        const contentId = heading?.getAttribute('aria-controls');
        const content = document.getElementById(contentId);

        heading?.parentNode
            .querySelectorAll('.active')
            .forEach((sibling) => this.select(sibling, false));
        content?.parentNode
            .querySelectorAll('.active')
            .forEach((sibling) => this.select(sibling, false));

        this.select(heading);
        this.select(content);

        this.dispatchEvent(tabShowEvent());

        if (focus) heading?.focus();
    }

    isPanelEmpty(panel) {
        return (
            !panel.querySelector('[data-filter-ignore]') &&
            panel.innerText.trim().length === 0 &&
            panel.children.length <= 1
        );
    }

    reselectTabs() {
        const [firstTab] = this.querySelectorAll(
            '[role=tab][aria-selected=true],[role=tab]'
        );

        this.selectByHeading(firstTab);
    }

    connectedCallback() {
        // Remove Tab Parts(panels/heading) by empty panels
        Array.from(this.querySelectorAll('[role=tabpanel]'))
            .filter(this.isPanelEmpty)
            .forEach(this.removeByPanel);

        this.reselectTabs();
    }
}

customElements.define(tagName, TabContainer);
