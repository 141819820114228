import { isInExperienceEditor } from '@cfed/theming/js/helpers';
import { SEARCH_RESULTS_LOADED } from '../lib/events';
import { searchResultsEmptyEvent } from '../lib/site-search-events';
import { SiteSearchResultModel } from './model';
import { SiteSearchResultView } from './view';

export default class SearchResultsCustomElement extends HTMLElement {
    templateManager;

    constructor() {
        super();

        this.addEventListener(
            SEARCH_RESULTS_LOADED,
            this._initSearchResultsEmptyAdapter.bind(this)
        );
    }

    _initSearchResultsEmptyAdapter(evt) {
        const { resultData } = evt.detail;
        const isEmpty = resultData?.results?.length === 0;

        if (isEmpty) {
            evt.target.dispatchEvent(searchResultsEmptyEvent());
        }
    }

    async connectedCallback() {
        if (isInExperienceEditor()) return;

        const view = new SiteSearchResultView({
            el: this,
            model: new SiteSearchResultModel(),
            template: this.templateManager,
        });

        this.classList.add('initialized');
    }
}
