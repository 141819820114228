import Backbone from 'backbone';
import queryModel from '../lib/site-search.query';
import vent from '../lib/site-search-events';
import { sortingParamName, offsetParamName } from '../lib/params';
import { translateParamName } from '../lib/helpers';

export const SiteSearchResultModel = Backbone.Model.extend(
    /** @lends module:siteSearchResults.SiteSearchResultModel.prototype **/
    {
        defaults: {
            dataProperties: {},
            blockNextRequest: false,
            noResultsText: '',
            nullSearchText: '',
            showNullSearchText: false,
            isNullSearch: false,
            resultData: {},
            loadingInProgress: false,
            loadingMoreInProgress: false,
            resultDataMore: {},
            loadMoreOffset: 0,
            loadMore: false,
            showBestBets: false,
            showImage: false,
            showPdfPages: false,
            hideAuthors: false,
            filteredList: false,
            noResultsTopicsText: '',
        },
        /**
         * Listens for changes on data from server
         * @listens module:XA.component.siteSearch.vent~event:facet-data-loaded
         * @listens module:XA.component.siteSearch.vent~event:results-loading
         */
        initialize() {
            var queryObj = queryModel.getQueryObj(),
                signature = encodeURIComponent(
                    this.get('dataProperties').sig
                ),
                offsetSigKey = translateParamName(
                    signature,
                    offsetParamName
                );

            //If there is "e" in query object, then one of page selectors on the page is global (not assigned to any of the
            //search results - in such case it will paginate all search results without signature
            if (
                queryObj.hasOwnProperty(offsetParamName) &&
                signature === ''
            ) {
                this.set('loadMoreOffset', parseInt(queryObj.e));
            }

            //checks if there is page selector assigned to this specific search results
            if (queryObj.hasOwnProperty(offsetSigKey)) {
                this.set(
                    'loadMoreOffset',
                    parseInt(queryObj[offsetSigKey])
                );
            }

            vent.on('results-loaded', this.resultsLoaded.bind(this));
            vent.on('results-loading', this.resultsLoading.bind(this));
        },
        /**
         * Sets value of blockNextRequest variable
         * @param {String} value new value of blockNextRequest variable
         * @memberof module:siteSearchResults.SiteSearchResultModel
         * @alias module:siteSearchResults.SiteSearchResultModel#blockRequests
         */
        blockRequests(value) {
            this.set('blockNextRequest', value);
        },
        /**
         * Returns value of blockNextRequest variable
         * @memberof module:siteSearchResults.SiteSearchResultModel
         * @alias module:siteSearchResults.SiteSearchResultModel#checkBlockingRequest
         * @returns {String} value of blockNextRequest variable
         */
        checkBlockingRequest() {
            return this.get('blockNextRequest');
        },
        /**
         * Returns value of component offset from query parameters
         * @memberof module:siteSearchResults.SiteSearchResultModel
         * @alias module:siteSearchResults.SiteSearchResultModel#getMyOffset
         * @returns {String|0} value of offset
         */
        getMyOffset() {
            var queryObj = queryModel.getQueryObj(),
                signature = encodeURIComponent(
                    this.get('dataProperties').sig
                ),
                offsetSigKey = translateParamName(
                    signature,
                    offsetParamName
                );

            if (queryObj.hasOwnProperty(offsetSigKey)) {
                return queryObj[offsetSigKey];
            }
            return 0;
        },
        /**
         * Returns value of component sort code from query parameters
         * @memberof module:siteSearchResults.SiteSearchResultModel
         * @alias module:siteSearchResults.SiteSearchResultModel#getMySorting
         * @returns {String|0} value of sort code
         */
        getMySorting() {
            var queryObj = queryModel.getQueryObj(),
                signature = encodeURIComponent(
                    this.get('dataProperties').sig
                ),
                sortingSigKey = translateParamName(
                    signature,
                    sortingParamName
                );

            if (queryObj.hasOwnProperty(sortingSigKey)) {
                return queryObj[sortingSigKey];
            }
            return this.get('dataProperties').defaultSortOrder;
        },
        /**
         * Updates model with new data from resultData parameter
         * @param {Object} resultsData new component data
         * @memberof module:siteSearchResults.SiteSearchResultModel
         * @alias module:siteSearchResults.SiteSearchResultModel#resultsLoaded
         */
        resultsLoaded(resultsData) {
            var signature = encodeURIComponent(
                this.get('dataProperties').sig
            );

            if (signature === resultsData.searchResultsSignature) {
                if (this.get('loadMore')) {
                    this.set({ resultDataMore: resultsData.data });
                    this.set({ loadingMoreInProgress: false });
                    this.unset('loadMore', { silent: true });
                    //Trigger resultDataMore change
                    vent.trigger('resultDataMore-changed');
                } else {
                    this.set({ resultData: resultsData.data });
                    this.set({ loadingInProgress: false });
                    //Trigger resultData change
                    vent.trigger('resultData-changed');
                }
                this.blockRequests(false);
            }
        },
        /**
         * Manages loadingMoreInProgress model variable
         * @param {Object} cid component id
         * @memberof module:siteSearchResults.SiteSearchResultModel
         * @alias module:siteSearchResults.SiteSearchResultModel#resultsLoading
         */
        resultsLoading({ cid }) {
            if (this.cid == cid) {
                if (this.get('loadMore')) {
                    this.set({ loadingMoreInProgress: true });
                } else {
                    this.set({ loadingInProgress: true });
                }
            }
        },
    }
);