import Backbone from 'backbone';
import jQuery from "jquery";
import vent from '../lib/site-search-events';
import queryModel from '../lib/site-search.query';
import analyticsManager from '../lib/site-analytics';
import { dateFromParamName, dateToParamName } from '../lib/params';

export const SiteSearchFiltersView = Backbone.View.extend(
    /** @lends module:siteSearchFilters.SiteSearchFiltersView.prototype **/
    {
        /**
         *Sets up watchers for change on model
         * @memberof module:siteSearchFilters.SiteSearchFiltersView.prototype
         */
        initialize() {
            var dataProperties = this.$el.data();
            if (this.model) {
                this.model.set({
                    dataProperties: dataProperties.properties,
                    signatures: this.model.getSignatures(
                        dataProperties.properties.searchResultsSignatures
                    ),
                    yearsRequiredErrorText: dataProperties.properties.yearsRequiredErrorText,
                    dateRangeErrorText: dataProperties.properties.dateRangeErrorText,
                    showmonth: dataProperties.properties.showmonth
                });
            }
            var fromMonths = "1",
                toMonths = "12",
                form = this.$el.find('form');
            if (this.model.get('showmonth') == "1") {
                fromMonths = form.find('select[name=fromMonths]');
                toMonths = form.find('select[name=toMonths]')
            }
            var inst = this,
                fromYearsSelect = form.find('select[name=fromYears]'),
                fromMonthsSelect = fromMonths,
                toYearsSelect = form.find('select[name=toYears]'),
                toMonthsSelect = toMonths,
                notificationBox = this.$el.find('div .cf-alert');

            //Set reference to all selects
            this.fromYearsSelect = fromYearsSelect;
            this.fromMonthsSelect = fromMonthsSelect;
            this.toYearsSelect = toYearsSelect;
            this.toMonthsSelect = toMonthsSelect;
            this.notificationBox = notificationBox;



            //Set Year Selects
            for (let i = 0; i < this.model.get('years').length; i++) {
                let optionValue = this.model.get('years')[i];
                this.addOption(optionValue.toString(), optionValue, fromYearsSelect);
                this.addOption(optionValue.toString(), optionValue, toYearsSelect);
            }

            if (this.model.get('showmonth') == "1") {
                //Set Month Selects
                for (let i = 0; i < this.model.get('months').length; i++) {
                    let optionText = this.model.get('monthNames')[i];
                    let optionValue = this.model.get('months')[i];
                    this.addOption(optionText, optionValue, fromMonthsSelect);
                    this.addOption(optionText, optionValue, toMonthsSelect);
                }
            }

            //Set Date Selects to initial value from URL.
            this.setFromUrl();
            vent.on('results-loaded', this.resultsLoaded.bind(this));


        },
        resultsLoaded(_) {
            let searchParams = new URLSearchParams(window.location.search)
            if (searchParams.has(dateFromParamName)) {
                return;
            }
            this.clearFiltersUI();
        },
        /**
         * list of events for Backbone View
         * @memberof module:siteSearchFilters.SiteSearchFiltersView
         * @alias module:siteSearchFilters.SiteSearchFiltersView#events
         */
        events: {
            'click .site-search-filters__clear-filters': 'clearFilters',
            'submit form': 'updateFilters'
        },
        /**
         * Adds a Select Option to a select element
         * @memberof module:siteSearchFilters.SiteSearchFiltersView
         * @param {string} text Text for option
         * @param {string} value Value for option
         * @param {JQuery<Element>} JQuery Select Element
         * @alias module:siteSearchFilters.SiteSearchFiltersView#addOption
         */
        addOption(text, value, select) {
            let option = jQuery(new Option(text, value));
            select.append(option);
        },
        /**
         * Sets selected option on date selects based on URL
         * @memberof module:siteSearchFilters.SiteSearchFiltersView            
         * @alias module:siteSearchFilters.SiteSearchFiltersView#setFromUrl
         */
        setFromUrl() {
            let searchParams = new URLSearchParams(window.location.search),
                dateFromParamValue = searchParams.get(dateFromParamName),
                dateToParamValue = searchParams.get(dateToParamName);

            if (dateFromParamValue != null && dateToParamValue != null) {
                let dateFromParts = dateFromParamValue.split("-");
                let dateToParts = dateToParamValue.split("-");

                if (dateFromParts.length == 3) {
                    this.fromYearsSelect.val(parseInt(dateFromParts[0]));
                    if (this.model.get('showmonth') == "1") {
                        this.fromMonthsSelect.val(parseInt(dateFromParts[1]));
                    }
                }
                if (dateToParts.length == 3) {
                    this.toYearsSelect.val(parseInt(dateToParts[0]));
                    if (this.model.get('showmonth') == "1") {
                        this.toMonthsSelect.val(parseInt(dateToParts[1]));
                    }
                }

                //validate values
                if (new Date(dateFromParamValue) > new Date(dateToParamValue)) {
                    this.showWarning(this.model.get('dateRangeErrorText'));
                    return;
                }
            }
        },
        /**
         * Clears the filters and triggers a search
         * @memberof module:siteSearchFilters.SiteSearchFiltersView
         * @param {jQuery<Event>} event jQuery event object
         * @alias module:siteSearchFilters.SiteSearchFiltersView#clearFilters
         */
        clearFilters(event) {
            event.preventDefault();
            this.notificationBox.hide();
            this.clearFiltersUI();
            let signatures = this.model.get('signatures');
            //Update queryModel and URL
            queryModel.removeQueryDateRange(signatures);
        },

        clearFiltersUI() {
            this.fromYearsSelect[0].selectedIndex = 0;
            this.toYearsSelect[0].selectedIndex = 0;
            if (this.model.get('showmonth') == "1") {
                this.fromMonthsSelect[0].selectedIndex = 0;
                this.toMonthsSelect[0].selectedIndex = 0;
            }
        },

        /**
         * Updates hash value and makes redirect based on value from search filters
         * @memberof module:siteSearchFilters.SiteSearchFiltersView
         * @param {jQuery<Event>} event jQuery event object
         * @alias module:siteSearchFilters.SiteSearchFiltersView#updateFilters
         */
        updateFilters(event) {
            event.preventDefault();
            this.notificationBox.hide();

            var dataProperties = this.model.get('dataProperties'),
                signatures = this.model.get('signatures'),
                selectedFromYear = this.fromYearsSelect.find(":selected").val(),
                selectedToYear = this.toYearsSelect.find(":selected").val(),
                selectedFromMonth = "1",
                selectedToMonth = "12";

            if (this.model.get('showmonth') == "1") {
                selectedFromMonth = this.fromMonthsSelect.find(":selected").val(),
                selectedToMonth = this.toMonthsSelect.find(":selected").val();
            }
            analyticsManager.PushAnalyticsEvent('custom_event_search_date_filter', {
                SelectedFromYear: selectedFromYear,
                SelectedFromMonth: selectedFromMonth,
                SelectedToYear: selectedToYear,
                SelectedToMonth: selectedToMonth,
            });

            if (selectedFromYear === '--' || selectedToYear === '--') {
                this.showWarning(this.model.get('yearsRequiredErrorText'));
                return;
            }

            if (selectedFromYear > selectedToYear) {
                this.showWarning(this.model.get('dateRangeErrorText'));
                return;
            }

            //Determine Month values
            let fromMonth = (selectedFromMonth !== '--') ? parseInt(selectedFromMonth) : 1;
            let toMonth = (selectedToMonth !== '--') ? parseInt(selectedToMonth) : 12;

            if (selectedFromYear === selectedToYear && fromMonth > toMonth) {
                this.showWarning(this.model.get('dateRangeErrorText'));
                return;
            }

            //Calculate TO Days based on month selected. FROM Days is always 1
            let toDays = new Date(selectedToYear, toMonth, 0).getDate();

            //Determine dates
            let fromDate = `${selectedFromYear}-${fromMonth}-1`;
            let toDate = `${selectedToYear}-${toMonth}-${toDays}`;

            //console.log('From Date: ' + fromDate);
            //console.log('To Date: ' + toDate);

            queryModel.updateQueryDateRange(fromDate, toDate, signatures);
        },
        /**
         * Shows a warning message for form validation
         * @memberof module:siteSearchFilters.SiteSearchFiltersView
         * @alias module:siteSearchFilters.SiteSearchFiltersView#showWarning
         */
        showWarning(message) {
            this.notificationBox.find(".alert-text-wrapper").html(message);
            this.notificationBox.show();
        }
    }
);
