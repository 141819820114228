import accordion from './cf-accordion';
import './tab';
import hubSpotForm from './hubspot';
import embeddedVideo from './video/embedded-video';
import externalLinkFix from './externallinkfix';
import equations from './equations';
import PageTools from './page-tools';
import VideoPlayer from './video/video-player';

export default function initGeneralContent(domain) {
    accordion().init();
    embeddedVideo().init();
    externalLinkFix(domain).init();
    equations().init();

    customElements.define('page-tools', PageTools);
    customElements.define('cf-hubspot-form', hubSpotForm);
    customElements.define('video-player', VideoPlayer);
}
