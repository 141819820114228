export const queryParamName = 'q';
export const sortingParamName = 'o';
export const offsetParamName = 'e';
export const pageSizeParamName = 'p';
export const pagingParamName = 'pg';
export const dateFromParamName = 'df';
export const dateToParamName = 'dt';
export const signatureParamName = 'sig';
export const filterParamNamePrefix = 'f-';
export const buildContextualUrlsParamName = 'bcu';
export const pagePathnameParamName = 'ppn';
export const facetsParamName = 'fp';
export const showNullSearchTextParamName = 'snt';
export const showBestBetsTextParamName = 'sbb';
export const showImageTextParamName = 'si';
export const showPdfPagesTextParamName = 'sp';
export const hideAuthorsTextParamName = 'au';
export const filteredListTextParamName = 'fl';
export const taxonomyContextParamName = 'tcid';
